import * as types from './types';

const INITIAL_STATE = {
  loading: {},
  error: {}
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.START_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload]: true
        },
        error: {
          ...state.error,
          [payload]: null
        }
      };

    case types.STOP_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload]: false
        }
      };

    case types.LOADING_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.scope]: false
        },
        error: {
          ...state.error,
          [payload.scope]: payload.error
        }
      };

    default:
      return state;
  }
};

export default reducer;
