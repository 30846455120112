import reducer from './reducers';

import * as actions from './actions';
import * as operations from './operations';
import * as selectors from './selectors';

export {
  actions,
  operations,
  selectors
};

export default reducer;
