import * as actions from './actions';

import { actions as uiActions } from '../ui';
import { actions as redirectActions } from '../redirect';
import { actions as trackingActions } from '../tracking';
import { auth } from '../../firebase';
import { AuthException, ValidationException } from './exceptions';

const ui = uiActions.forScope('auth');

export const logInWithEmailAndPassword = (email, password, rememberMe) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    try {
      await auth.signIn(email, password);

      dispatch(trackingActions.trackEvent('log-in', 'logged in with email and password'));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const logOut = (error = undefined) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    await auth.signOut();

    dispatch(actions.loggedOut());

    if (error && error.code) {
      dispatch(
        ui.error(new AuthException(error.code, error.message))
      );
      dispatch(redirectActions.redirect('/auth/login'));
    } else {
      dispatch(trackingActions.trackEvent('log-in', 'logged out'));
    }

    dispatch(ui.stopLoading());
  };

export const restoreSession = (user) =>
  async (dispatch) => {
    const { displayName, email, uid } = user;

    dispatch(actions.loggedIn({
      name: displayName || email,
      email,
      uid
    }));
  };

export const logInWithFacebook = () =>
  async (dispatch) => {
    dispatch(trackingActions.trackEvent('log-in', 'log in with facebook'));

    try {
      await auth.signInWithFacebook();
    } catch (e) {
      dispatch(ui.error(e));
    }
  };

export const logInWithGoogle = () =>
  async (dispatch) => {
    dispatch(trackingActions.trackEvent('log-in', 'log in with google'));

    try {
      await auth.signInWithGoogle();
    } catch (e) {
      dispatch(ui.error(e));
    }
  };

export const resetPassword = (email) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    try {
      await auth.sendResetPasswordEmail(email);

      dispatch(redirectActions.redirect('/auth/reset-password-confirmation', { email }));
      dispatch(trackingActions.trackEvent('log-in', 'sent reset password'));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const changePassword = (code, password) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    try {
      await auth.confirmChangePassword(code, password);

      dispatch(redirectActions.redirect('/auth/login'));
      dispatch(trackingActions.trackEvent('log-in', 'changed password'));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const register = (email, password, repeatPassword) =>
  async (dispatch) => {
    dispatch(uiActions.startLoading('register'));

    try {
      if (password !== repeatPassword) {
        throw new ValidationException('validation/passwords-does-not-match', 'Passwords does not match');
      }

      await auth.signUp(email, password);

      dispatch(redirectActions.redirect('/auth/register-confirmation', { email }));
      dispatch(trackingActions.trackEvent('register', 'confirm e-mail'));
    } catch (e) {
      dispatch(uiActions.error('register', e));
    }

    dispatch(uiActions.stopLoading('register'));
  };

export const registerWithFacebook = () =>
  async (dispatch) => {
    dispatch(trackingActions.trackEvent('register', 'register with facebook'));

    try {
      await auth.signUpWithFacebook();
    } catch (e) {
      dispatch(uiActions.error('register', e));
    }
  };

export const registerWithGoogle = () =>
  async (dispatch) => {
    dispatch(trackingActions.trackEvent('register', 'register with google'));

    try {
      await auth.signUpWithGoogle();
    } catch (e) {
      dispatch(uiActions.error('register', e));
    }
  };

export const verifyEmail = (code) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    try {
      await auth.confirmEmail(code);

      dispatch(redirectActions.redirect('/auth/login'));
      dispatch(trackingActions.trackEvent('register', 'e-mail confirmed'));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const updatePassword = (password) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    try {
      await auth.updatePassword(password);

      dispatch(redirectActions.redirect('/auth/profile'));
      dispatch(trackingActions.trackEvent('profile', 'changed password'));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };
