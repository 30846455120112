import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { PrivateRoute } from '../../containers/auth';
import AddCode from './AddCode';
import AddLink from './AddLink';
import Create from './Create';
import Details from './Details';
import Offers from './Offers';
import SearchResults from './SearchResults';

const Routes = () =>
  <Switch>
    <Route exact path="/" render={ () => <Redirect to="/offers/popular" /> } />

    {
      /*
        Temporarly disable Hot Deals and New Arrivals
        <Route exact path="/" render={ () => <Redirect to="/offers/hot-deals" /> } />
        
        <Route exact path="/offers/:category(hot-deals|new-arrivals|popular)" component={ Offers } />
       */
    }
    <Route exact path="/offers/:category(popular)" component={ Offers } />
    <Route path="/offers/search" component={ SearchResults } />
    <PrivateRoute path="/offer/new" component={ Create } />
    <PrivateRoute path="/offer/:id/add-code" component={ AddCode } />
    <PrivateRoute path="/offer/:id/add-link" component={ AddLink } />
    <Route path="/offer/:id" component={ Details } />
  </Switch>

export {
  AddCode,
  AddLink,
  Create,
  Details,
  Offers,
  SearchResults,
  Routes
};
