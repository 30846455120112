import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Snapshot = ({ id, logo, title, description, deadline, bonus, add }) =>
  <div className="offer">
    <Link to={`/offer/${ id }`}>
      <div className="img-wrapper text-center">
        <img
          src={ logo }
          alt={ title }
        />
      </div>

      <div className="description py-2 px-3">
        <h3>{ title }</h3>
        <p className="text-ellipsis-two-lines">
          { description }
        </p>

        {
          deadline &&
          <p>
            Termin ważności: { deadline }
          </p>
        }
      </div>
    </Link>

    <div className="buttons px-3">
      <p className="green big text-ellipsis-one-line">
        Bonus: { bonus }
      </p>

      <div className="row">
        <div className="col-6 pr-1">
          <Link
            to={`/offer/${ id }`}
            className="btn btn-primary"
            role="button"
          >
            Zobacz ofertę
          </Link>
        </div>
        <div className="col-6 pl-1">
          { add }
        </div>
      </div>
    </div>
  </div>

Snapshot.propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  deadline: PropTypes.string,
  bonus: PropTypes.string.isRequired,
  add: PropTypes.element
};

Snapshot.defaultProps = {
  logo: "",
  description: "",
  deadline: ""
};

export default Snapshot;
