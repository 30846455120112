import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Loadable } from '../../containers/shared';
import { Container } from '../../components/shared';
import { Details } from '../../containers/offer';
import { SearchBar } from '../../components/offer';
import { operations, selectors } from '../../reducers/offer';

const DetailsScreen = ({ match, offer, fetchOffer, trackOffer }) => {
  useEffect(() => {
    const { id } = match.params;

    fetchOffer(id);
    trackOffer(id);
  }, []);

  return (
    <Loadable scope="offers">
      <Container>
        {
          offer &&
          <>
            <SearchBar>
              Oferta | { offer.title }
            </SearchBar>

            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <Details offer={ offer } />
              </div>
            </div>
          </>
        }
      </Container>
    </Loadable>
  );
}

const mapStateToProps = (state, ownProps) => ({
  offer: selectors.getById(state, { id: ownProps.match.params.id })
});

const mapDispatchToProps = {
  fetchOffer: operations.fetchOfferDetails,
  trackOffer: operations.trackOfferSeen
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsScreen);
