import React from 'react';

import { AnonymousRoute, PrivateRoute } from '../../containers/auth';
import { QueryMatch } from '../../components/common';

import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import Profile from './Profile';
import Register from './Register';
import ResetPasswordEmailSent from './ResetPasswordEmailSent';
import UpdatePassword from './UpdatePassword';
import VerificationEmailSent from './VerificationEmailSent';
import VerifyEmail from './VerifyEmail';

const Routes = () =>
  <>
    <AnonymousRoute path="/auth/forgot-password" component={ ForgotPassword } />
    <AnonymousRoute path="/auth/login" component={ Login } />
    <AnonymousRoute path="/auth/register" component={ Register } />
    <AnonymousRoute path="/auth/reset-password-confirmation" component={ ResetPasswordEmailSent } />
    <AnonymousRoute path="/auth/change-password" component={ ChangePassword } />
    <AnonymousRoute path="/auth/register-confirmation" component={ VerificationEmailSent } />
    <AnonymousRoute path="/auth/verify-email" component={ VerifyEmail } />
    <PrivateRoute path="/auth/profile" component={ Profile } />
    <PrivateRoute path="/auth/update-password" component={ UpdatePassword } />

    <AnonymousRoute
      path="/auth/action"
      render={ (props) =>
        <QueryMatch
          { ...props }
          paths={[
            { query: 'mode', value: 'resetPassword', redirect: '/auth/change-password' },
            { query: 'mode', value: 'verifyEmail', redirect: '/auth/verify-email' }
          ]}
        />
      }
    />
  </>

export {
  ChangePassword,
  ForgotPassword,
  Login,
  Register,
  ResetPasswordEmailSent,
  Routes
}
