function ApiException(code, message) {
  this.code = code;
  this.message = message;
  this.name = "ApiException";
}

ApiException.prototype.toString = function () {
  return `${this.name}: [${this.code}] ${this.message}`;
}

export {
  ApiException
};
