import moment from 'moment';
import md5 from 'md5';

import { ApiException } from './exceptions';
import { firestore, storage } from '../firebase';

export const getOffers = async () => {
  const offers = await firestore.getAll('offers');

  return offers
    .filter(
      ({ approved }) => approved === true
    )
    .filter(
      ({ deadline }) => !deadline || moment(deadline, 'DD-MM-YYYY').isAfter()
    );
};

export const getById = async (id) =>
  await firestore.getById('offers', id);

export const create = async ({ file, deadline, type, code, codePage, link, ...offer }, uid) => {
  const { id } = await firestore.create('offers', {
    ...offer,
    deadline: (deadline) ? moment(deadline).format('DD-MM-YYYY') : null,
    type,
    owner: uid,
    codePage: (type === 'code') ? codePage : null,
    isNew: false,
    isHotDeal: false
  });

  if (type === 'code') {
    await addCode(id, code, uid);
  } else if (type === 'link') {
    await addLink(id, link, uid);
  }

  await createStatisticsForOffer(id);
  await createLogoForOffer(id, file);

  return id;
};

const createStatisticsForOffer = async (id) =>
  await firestore.create('statistics', { views: 0 }, id);

const uploadLogoForOffer = async (id, file) =>
  await storage.upload(`logo/${id}/${file.name}`, file);

const createLogoForOffer = async (id, file) => {
  const logoUrl = await uploadLogoForOffer(id, file);

  await firestore.updateById('offers', id, { logo: logoUrl });
};

export const search = async (query) => {
  const offers = await getOffers();

  return offers.filter(
    ({ title, description, link, logo }) => `${title}${description}${link}${logo}`.match(new RegExp(query, 'ig'))
  );
};

export const trackById = async (id) =>
  await firestore.increaseById('statistics', id, 'views');

export const getStatistics = async () =>
  await firestore.getAll('statistics');

export const addCode = async (id, code, uid) => {
  const hash = md5(code);

  const exists = await firestore.doesExistById(`codes/${id}/code`, hash);

  if (exists) {
    throw new ApiException('api/offer-code-already-exists', 'Such code already exists');
  }

  await firestore.create(`codes/${id}/code`, {
    code,
    owner: uid,
    createdAt: moment().format('DD-MM-YYYY HH:mm:ss')
  }, hash);
};

export const addLink = async (id, link, uid) => {
  const hash = md5(link);

  const exists = await firestore.doesExistById(`links/${id}/link`, hash);

  if (exists) {
    throw new ApiException('api/offer-link-already-exists', 'Such link already exists');
  }

  await firestore.create(`links/${id}/link`, {
    link,
    owner: uid,
    createdAt: moment().format('DD-MM-YYYY HH:mm:ss')
  }, hash);
};

export const reportIncorrectCode = async (id, code, uid) => {
  await firestore.create(`reports/${id}/code`, {
    code,
    by: uid,
    reportedAt: moment().format('DD-MM-YYYY HH:mm:ss')
  });
};

export const reportIncorrectLink = async (id, link, uid) => {
  await firestore.create(`reports/${id}/link`, {
    link,
    by: uid,
    reportedAt: moment().format('DD-MM-YYYY HH:mm:ss')
  });
};

export const trackCode = async (id, code) =>
  await firestore.increaseById(`statistics/${id}/codes`, code, 'views');

export const trackLink = async (id, link) =>
  await firestore.increaseById(`statistics/${id}/links`, md5(link), 'views');
