import { firebase, firestore } from './config';
import { FirestoreException } from './exceptions';

export const getAll = async (collection) => {
  const documents = await firestore.collection(collection).get();

  return documents.docs.map(snapshotToDocument);
};

export const getById = async (collection, id) => {
  const document = await firestore.collection(collection).doc(id).get();

  if (document.exists) {
    return snapshotToDocument(document);
  }

  throw new FirestoreException('db/document-does-not-exist', 'Document does not exist');
};

export const create = async (collection, document, id = null) => {
  if (id) {
    return await firestore.collection(collection).doc(id).set(document);
  }

  return await firestore.collection(collection).add(document);
};

export const updateById = async (collection, id, data) =>
  await firestore.collection(collection).doc(id).update(data);

export const increaseById = async (collection, id, field) =>
  await firestore.collection(collection)
    .doc(id)
    .set({ [field]: firebase.firestore.FieldValue.increment(1) }, { merge: true });

export const doesExistById = async (collection, id) => {
  const document = await firestore.collection(collection).doc(id).get();

  return document.exists;
};

const snapshotToDocument = (snapshot) => ({
  id: snapshot.id,
  ...snapshot.data()
});
