import * as types from './types';

import { tracker } from '../../analytics';

const middleware = (store) => (next) => (action) => {
  if (action.type === types.TRACK_PAGE_VIEW) {
    tracker.trackPageView(action.payload)
  } else if (action.type === types.TRACK_EVENT) {
    tracker.trackEvent(action.payload);
  }

  return next(action);
};

export default middleware;
