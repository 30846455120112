import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LabeledInput } from '../common';
import { ErrorMessage, LoadableButton } from '../shared';

const ForgotPassword = ({ loading, error, onResetPassword }) => {
  const [ email, setEmail ] = useState('');

  return (
    <div className="basic-page p-md-5 p-3 text-center">
        <p>Podaj adres e-mail podany podczas rejestracji,<br/> a my wyślemy Ci nowe hasło:</p>
        <form
          className="mt-3"
          onSubmit={ (e) => {
            e.preventDefault();

            onResetPassword(email);
          }}
        >
          <ErrorMessage error={ error } />
          <div className="form-group my-2">
            <LabeledInput
              type="email"
              id="email"
              placeholder="e-mail"
              required
              value={ email }
              onChange={ setEmail }
              label="Adres e-mail"
            />
          </div>

          <LoadableButton
            className="btn btn-primary btn-block"
            type="submit"
            loading={ loading }
          >
            Resetuj hasło
          </LoadableButton>
        </form>
    </div>
  );
}

ForgotPassword.propTypes = {
  ...ErrorMessage.propTypes,
  loading: PropTypes.bool.isRequired,
  onResetPassword: PropTypes.func.isRequired
};

export default ForgotPassword;
