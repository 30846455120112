import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '.';

const LabeledInput = ({ label, className, inputClassName, id, ...otherProps }) =>
  <>
    <Input
      { ...otherProps }
      className={ inputClassName }
      id={ id }
    />

    <label
      htmlFor={ id }
      className={ className }
    >
      { label }
    </label>
  </>

LabeledInput.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  ...Input.propTypes,
  inputClassName: Input.propTypes.className,
  id: Input.propTypes.id,
};

LabeledInput.defaultProps = {
  ...Input.defaultProps,
  className: 'sr-only',
  inputClassName: Input.defaultProps.className,
  id: Input.defaultProps.id,
};

export default LabeledInput;
