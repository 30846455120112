import { actions as uiActions } from '../ui';
import { actions as redirectActions } from '../redirect';
import { contact } from '../../api';

const ui = uiActions.forScope('contact');

export const sendContactMessage = (email, message) =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    try {
      await contact.sendContactMessage(email, message);

      dispatch(
        redirectActions.redirect('/contact', { success: true })
      );
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };
