import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Loadable } from '../../containers/shared';
import { Container } from '../../components/shared';
import { AddCode, SearchBar } from '../../components/offer';
import { operations, selectors } from '../../reducers/offer';

const AddCodeScreen = ({ match, offer, fetchOffer, addCode }) => {
  const { id } = match.params;

  useEffect(() => {
    fetchOffer(id);
  }, []);

  return (
    <Container>
      <SearchBar>
        {
          offer &&
          <span>
            Oferta | { offer.title }
          </span>
        }
      </SearchBar>

      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <Loadable
            scope="offers"
            className="offer p-5"
            showContentWhenFailed={ true }
          >
            {
              offer &&
              <AddCode
                { ...offer }
                onAddCodeClick={ (code) => addCode(id, code) }
              />
            }
          </Loadable>
        </div>
      </div>
    </Container>
  );
}

const mapStateToProps = (state, ownProps) => ({
  offer: selectors.getById(state, { id: ownProps.match.params.id })
});

const mapDispatchToProps = {
  fetchOffer: operations.fetchOfferDetails,
  addCode: operations.addCode
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCodeScreen);
