import * as types from './types';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGGED_IN:
      return {
        ...state,
        user: action.payload.user
      };

    case types.LOGGED_OUT:
      return {
        ...state,
        user: undefined
      };

    default:
      return state;
  }
};

export default reducer;
