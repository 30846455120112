import * as types from './types';

import history from '../../history';

const middleware = (store) => (next) => (action) => {
  if (action.type === types.REDIRECT) {
    history.push({
      pathname: action.payload.to,
      state: action.payload.state
    });
  }

  return next(action);
};

export default middleware;
