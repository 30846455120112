import React from 'react';
import { connect } from 'react-redux';

import { Loadable } from '../../containers/shared';
import { Container } from '../../components/shared';
import { Form } from '../../components/contact';
import { selectors as authSelectors } from '../../reducers/auth';
import { operations } from '../../reducers/contact';

const ContactForm = ({ location, email, sendContactMessage }) => {
  const { state } = location;

  if (state && state.success) {
    return (
      <Container>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="basic-page p-md-5 p-3 mt-5 text-center">
              <p>
                Wiadomość została wysłana. Odpowiemy najszybciej jak możemy.
              </p>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Loadable scope="contact">
      <Container>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="offer offer-create p-5 mt-5">
              <Form
                email={ email }
                onSendForm={ sendContactMessage }
              />
            </div>
          </div>
        </div>
      </Container>
    </Loadable>
  );
}

const mapStateToProps = (state) => ({
  email: authSelectors.getUserEmail(state)
});

const mapDispatchToProps = {
  sendContactMessage: operations.sendContactMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
