import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyArobKdLpeaVytcSgw-GSrrv7Qgo_l-KVE",
  authDomain: "refcodes-510b9.firebaseapp.com",
  databaseURL: "https://refcodes-510b9.firebaseio.com",
  projectId: "refcodes-510b9",
  storageBucket: "refcodes-510b9.appspot.com",
  messagingSenderId: "230322348830"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();

export {
  auth,
  firebase,
  firestore,
  storage
};
