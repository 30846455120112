import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UploadFile = ({ label, className, labelClassName, id, onFileSelected }) => {
  const [ file, setFile ] = useState('');

  const onFileChange = (e) => {
    const f = e.target.files[0];

    setFile(f);
    onFileSelected(f);
  };

  return (
    <>
      <input
        type="file"
        accept="image/png, image/jpeg"
        className={ className }
        id={ id }
        required
        onChange={ onFileChange }
      />

      <label
        className={ labelClassName }
        htmlFor={ id }
      >
        { file && file.name }
        {
          !file &&
          <>
            <img
              src="/images/icon_cloud.png"
              alt={ label }
            />

            <span>{ label }</span>
          </>
        }
      </label>
    </>
  );
};

UploadFile.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  id: PropTypes.string.isRequired,
  onFileSelected: PropTypes.func.isRequired
};

UploadFile.defaultProps = {
  className: '',
  labelClassName: ''
};

export default UploadFile;
