import React from 'react';
import { withRouter } from 'react-router-dom';

export const GoBack = ({ history }) =>
  <button
    title="Powrót"
    id="back"
    onClick={ history.goBack }
  >
    <img src="/images/icon_back.svg" alt="Powrót"/>
  </button>

export default withRouter(GoBack);
