import reducer from './reducers';
import * as actions from './actions';
import * as selectors from './selectors';

export {
  actions,
  selectors
};

export default reducer;
