import React, { useState} from 'react';
import PropTypes from 'prop-types';

import { Input } from '../common';

const AddLink = ({ logo, title, onAddLinkClick }) => {
  const [ link, setLink ] = useState('');

  return (
    <form
      id="form-offer-add-link"
      onSubmit={ (e) => {
        e.preventDefault();

        onAddLinkClick(link);
      }}
    >
      <div className="row">
        <div className="col-sm-6 align-self-end">
          <img
            src={ logo }
            alt={ title }
          />
        </div>
      </div>
      <div className="py-3">
        <h3 className="my-2">{ title }</h3>
        <div className="row">
          <div className="col-md-8 align-self-end">
            <div className="form-group mt-4">
             <label htmlFor="offer-link">Link aktywacyjny</label>
             <i className="fas fa-link"></i>

             <Input
              type="url"
              id="offer-link"
              required
              value={ link }
              onChange={ setLink }
            />
            </div>
          </div>
          <div className="col-md-4 text-right align-self-end">
            <button className="btn btn-secondary d-block w-100" type="submit">
              Dodaj link
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

AddLink.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onAddLinkClick: PropTypes.func.isRequired
};

export default AddLink;
