import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Profile = ({ name, onLogOutClick }) =>
  <div className="offer p-5">
    <div className="row">
      Imię i nazwisko:&nbsp;<b>{ name }</b>
    </div>

    <div className="row pt-3">
      <div className="col-md-6 col-12 text-md-left text-center mb-2">
        <Link
          to="/auth/update-password"
          className="btn btn-primary"
        >
          Zmień hasło
        </Link>
      </div>

      <div className="col-md-6 col-12 text-md-right text-center mb-2">
        <button
          id="log-out"
          className="btn btn-secondary"
          onClick={ onLogOutClick }
        >
          Wyloguj się
        </button>
      </div>
    </div>
  </div>

Profile.propTypes = {
  name: PropTypes.string.isRequired,
  onLogOutClick: PropTypes.func.isRequired
};

export default Profile;
