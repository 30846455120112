import { combineReducers } from 'redux';

import auth from './auth';
import offer from './offer';
import ui from './ui';

const reducer = combineReducers({
  auth,
  offer,
  ui
});

export default reducer;
