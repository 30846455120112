import React from 'react';

import { Routes as AuthRoutes } from './auth';
import { Routes as ContactRoutes } from './contact';
import { Routes as OfferRoutes } from './offer';
import { Routes as SharedRoutes } from './shared';
import { Routes as StaticRoutes } from './static';

const Routes = () =>
  <>
    <AuthRoutes />
    <ContactRoutes />
    <OfferRoutes />
    <SharedRoutes />
    <StaticRoutes />
  </>

export {
  Routes
};
