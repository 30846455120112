import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import { Details, LoadableActivator } from '.';
import { LoadableButton } from '../shared';

const DetailsWithCode = ({ id, code, codePage, loadingReport, loadingActivator, onLoadActivatorClick, onReportCodeClick, ...otherProps }) =>
  <Details
    { ...otherProps }
    activator={
      <div>
        {
          codePage &&
          <>
            <h5 className="pt-2">Link do strony gdzie nalezy wpisać kod</h5>
            <p className="pb-1 wrap">
              <a
                href={ codePage }
                target="_blank"
                rel="noopener noreferrer"
                className="green"
              >
                { codePage }
              </a>
            </p>
          </>
        }

        <p className="green py-4">
        <span className="mr-2">Twój kod:&nbsp;</span>

          <LoadableActivator
            activator={ code }
            loadActivatorText="Pokaż kod"
            loading={ loadingActivator}
            onLoadActivatorClick={ onLoadActivatorClick }
          >
            <CopyToClipboard text={ code }>
              <span className="medium bold copy-code" title="Kliknij aby skopiować">
                { code } <i className="fas fa-copy"></i>
              </span>
            </CopyToClipboard>
          </LoadableActivator>
        </p>
      </div>
    }
    report={
      <>
        {
          code &&
          <LoadableButton
            className="btn btn-secondary"
            type="button"
            loading={ loadingReport }
            onClick={ onReportCodeClick }
          >
            Zgłoś wadliwy kod
          </LoadableButton>
        }
      </>
    }
    add={
      <Link
        to={`/offer/${ id }/add-code`}
        className="btn btn-fluorescent"
        role="button"
      >
        Dodaj swój kod
      </Link>
    }
  />

DetailsWithCode.propTypes = {
  ...Details.propTypes,
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  loadingReport: PropTypes.bool.isRequired,
  loadingActivator: PropTypes.bool.isRequired,
  onLoadActivatorClick: PropTypes.func.isRequired,
  onReportCodeClick: PropTypes.func.isRequired
}

export default DetailsWithCode;
