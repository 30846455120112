import React from 'react';

const AuthUserContext = React.createContext({
  authentication: {
    authenticated: false,
    checking: true
  }
});

export default AuthUserContext;
