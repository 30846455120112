import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';

const AnonymousRoute = ({ component: Component, render, ...otherProps }) => {
  const context = useContext(AuthUserContext);
  const { checking, authenticated } = context.authentication;

  if (checking) {
    return null;
  }

  return (
    <Route
      { ...otherProps }
      render={
        (props) => {
          if (authenticated) {
            return (
              <Redirect to='/' />
            );
          }

          if (Component) {
            return <Component { ...props } />;
          }

          return render(props);
        }
      }
    />
  );
}

AnonymousRoute.contextTypes = {
  authentication: PropTypes.shape({
    checking: PropTypes.bool,
    authenticated: PropTypes.bool.isRequired
  })
};

export default AnonymousRoute;
