import React from 'react';

import { Container } from '../../components/shared';

const LearnMore = () =>
  <Container>
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="p-5 mt-5 learn-more">
          <h2>Na czym polega refkody.pl?</h2>

          <ol>
            <li>
              Dzięki stronie refkody.pl możesz dodawać swoje kody referencyjne/polecające (do serwisów internetowych, finansowych, afiliacyjnych, itp) i dzięku temu zarabiać oraz dostawać bonusy. Najlepszym przykladem może być aribnb.com, lime, booking.com czy też konto bankowe w Santander
            </li>

            <li>
              Możesz znaleźć kod referencyjny do nowej usługi i dzięki temu skorzystać z bonusów referencyjnych. Zakładasz nowe konto u dostawcy usług, w banku lub grze? Sprawdź czy nie ma kodów polecających na naszej stronie
            </li>
          </ol>

          <h2>Dlaczego moja oferta kodu referyncejnego nie pojawia sie natychmiast?</h2>

          <p>
            Każda nowa oferta jest moderowana. Jako administratorzy strony chcemy zapewnić, że instrukcje są jasne i oferty nie powtarzają sie. Będziemy się starali na bieżąco akceptować Wasze oferty
          </p>

          <h2>Czy kody wyświetlają sie losowo?</h2>

          <p>
            Zastosowaliśmy algorytm, który wyświetla kody losowo. Jednak kody tych z Was, którzy dodają ofertę będą wyświetlały się z większą częstotliwością
          </p>

          <h2>Dlaczego kod osoby dodającej ofertę wyświetla się częściej?</h2>

          <p>
            Dodanie oferty wiąże się z większym nakładem pracy (opisanie oferty, dodanie linków oraz grafiki, itp.). Dlatego będziemy nagradzać tych, którzy poświęcą swój czas na dodanie treści.
          </p>

          <h2>Dlaczego muszę sie logować, żeby dodać kod?</h2>

          <p>
            No ej, strona jest za free więc rozkminiamy (moja dziewczyna mówi, że kombinujemy źle sie kojarzy) jak na tym zarobić.
          </p>

          <h2>Na czym strona zarabia?</h2>

          <p>
            Obecnie nie zbudowaliśmy modelu monetyzacji ale wraz ze wzrostem bazy użytkowników będziemy rozwijać systemy poleceń i afiliacji
          </p>

          <h2>Czy planujecie dodać nowe funkcjonalności?</h2>

          <p>
            Tak! Mamy masę pomysłów ale piszcie w formularzu kontaktowym co byście chcieli zobaczyć jako pierwsze
          </p>
        </div>
      </div>
    </div>
  </Container>

export default LearnMore;
