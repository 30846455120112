import * as actions from './actions';
import * as selectors from './selectors';

import { actions as uiActions } from '../ui';
import { actions as redirectActions } from '../redirect';
import { selectors as authSelectors } from '../auth';
import { activator, offers } from '../../api';

const ui = uiActions.forScope('offers');

export const fetchOffers = () =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    const cached = selectors.hasCachedOffers(getState());

    if (!cached) {
      dispatch(actions.fetchedOffers(await offers.getOffers()));
      dispatch(actions.fetchedStatistics(await offers.getStatistics()));
    }

    dispatch(ui.stopLoading());
  };

export const fetchOfferDetails = (id) =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    const offer = selectors.getById(getState(), { id });

    if (!offer) {
      try {
        const details = await offers.getById(id);

        dispatch(actions.fetchedOfferDetails(details));
      } catch (e) {
        dispatch(ui.error(e));
      }
    }

    dispatch(ui.stopLoading());
  };

export const trackOfferSeen = (id) =>
  async () => {
    try {
      await offers.trackById(id);
    } catch (e) {
      // no-op
    }
  };

export const create = (offer) =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    const uid = authSelectors.getUserId(getState());

    try {
      await offers.create(offer, uid);

      dispatch(redirectActions.redirect('/offer/new', { created: true }));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const search = (query) =>
  async (dispatch) => {
    dispatch(ui.startLoading());

    dispatch(actions.fetchedSearchResults(
      await offers.search(query)
    ));

    dispatch(ui.stopLoading());
  };

export const addCode = (id, code) =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    const uid = authSelectors.getUserId(getState());

    try {
      await offers.addCode(id, code, uid);

      dispatch(redirectActions.redirect(`/offer/${id}`));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const addLink = (id, link) =>
  async (dispatch, getState) => {
    dispatch(ui.startLoading());

    const uid = authSelectors.getUserId(getState());

    try {
      await offers.addLink(id, link, uid);

      dispatch(redirectActions.redirect(`/offer/${id}`));
    } catch (e) {
      dispatch(ui.error(e));
    }

    dispatch(ui.stopLoading());
  };

export const fetchCode = (id) =>
  async (dispatch, getState) => {
    dispatch(uiActions.startLoading('offer-activator'));

    try {
      const { code } = await activator.getCode(id);

      dispatch(trackCode(id, code));

      dispatch(actions.fetchedActivator(id, code));
      dispatch(uiActions.stopLoading('offer-activator'));
    } catch (e) {
      dispatch(uiActions.error('offer-activator', e));
    }
  };

export const fetchLink = (id) =>
  async (dispatch, getState) => {
    dispatch(uiActions.startLoading('offer-activator'));

    try {
      const { link } = await activator.getLink(id);

      dispatch(trackLink(id, link));

      dispatch(actions.fetchedActivator(id, link));
      dispatch(uiActions.stopLoading('offer-activator'));
    } catch (e) {
      dispatch(uiActions.error('offer-activator', e));
    }
  };

export const reportIncorrectCode = (id, code) =>
  async (dispatch, getState) => {
    dispatch(uiActions.startLoading('offer-report'));

    if (authSelectors.isLoggedIn(getState())) {
      const uid = authSelectors.getUserId(getState());

      try {
        await offers.reportIncorrectCode(id, code, uid);
      } catch (e) {
        dispatch(uiActions.error('offer-report', e));
      }
    } else {
      dispatch(redirectActions.redirect('/auth/login'));
    }

    dispatch(uiActions.stopLoading('offer-report'));
  };

export const reportIncorrectLink = (id, link) =>
  async (dispatch, getState) => {
    dispatch(uiActions.startLoading('offer-report'));

    if (authSelectors.isLoggedIn(getState())) {
      const uid = authSelectors.getUserId(getState());

      try {
        await offers.reportIncorrectLink(id, link, uid);
      } catch (e) {
        dispatch(uiActions.error('offer-report', e));
      }
    } else {
      dispatch(redirectActions.redirect('/auth/login'));
    }

    dispatch(uiActions.stopLoading('offer-report'));
  };

export const trackCode = (id, code) =>
  async () => await offers.trackCode(id, code);

export const trackLink = (id, link) =>
  async () => await offers.trackLink(id, link);
