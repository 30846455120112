import * as types from './types';

export const trackPageView = (page) => ({
  type: types.TRACK_PAGE_VIEW,
  payload: page
});

export const trackEvent = (category, action) => ({
  type: types.TRACK_EVENT,
  payload: {
    category,
    action
  }
});
