import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { operations, selectors } from '../../reducers/offer';
import { selectors as uiSelectors } from '../../reducers/ui';
import { DetailsWithCode, DetailsWithLink } from '../../components/offer';

const Details = ({
  offer,
  activator,
  isLoadingReport,
  isLoadingActivator,
  fetchCode,
  fetchLink,
  trackCode,
  trackLink,
  reportIncorrectCode,
  reportIncorrectLink,
  ...otherProps
}) => {
  switch (offer.type) {
    case 'code':
      return (
        <DetailsWithCode
          { ...otherProps }
          { ...offer }
          code={ activator }
          loadingReport={ isLoadingReport }
          loadingActivator={ isLoadingActivator}
          onLoadActivatorClick={ () => fetchCode(offer.id) }
          onReportCodeClick={ () => reportIncorrectCode(offer.id, activator) }
        />
      );

    case 'link':
      return (
        <DetailsWithLink
          { ...otherProps}
          { ...offer }
          link={ activator }
          loadingReport={ isLoadingReport }
          loadingActivator={ isLoadingActivator}
          onLoadActivatorClick={ () => fetchLink(offer.id) }
          onReportLinkClick={ () => reportIncorrectLink(offer.id, activator) }
        />
      );

    default:
      return null;
  }
}

Details.propTypes = {
  offer: PropTypes.shape().isRequired
};

const mapStateToProps = (state, ownProps) => ({
  activator: selectors.getActivator(state, { id: ownProps.offer.id }),
  isLoadingReport: uiSelectors.isLoading(state, { scope: 'offer-report' }),
  isLoadingActivator: uiSelectors.isLoading(state, { scope: 'offer-activator' })
});

const mapDispatchToProps = {
  fetchCode: operations.fetchCode,
  fetchLink: operations.fetchLink,
  trackCode: operations.trackCode,
  trackLink: operations.trackLink,
  reportIncorrectCode: operations.reportIncorrectCode,
  reportIncorrectLink: operations.reportIncorrectLink
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
