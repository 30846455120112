import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

const TextArea = ({ id, className, placeholder, required, value, rows, onChange }) =>
  <TextareaAutosize
    id={ id }
    className={ className }
    placeholder={ placeholder }
    required={ required }
    value={ value }
    rows={ rows }
    onChange={ (event) => onChange(event.target.value) }
  />

TextArea.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

TextArea.defaultProps = {
  id: '',
  className: 'form-control',
  placeholder: '',
  required: false,
  rows: 3
};

export default TextArea;
