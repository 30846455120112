import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LabeledInput } from '../common';
import { ErrorMessage, LoadableButton } from '../shared';

const LogInForm = ({ loading, error, onLogIn, onLogInWithFacebook, onLogInWithGoogle }) => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ rememberMe, setRememberMe ] = useState(true);

  return (
    <div className="col-lg-6 px-lg-5 text-center align-self-end" id="login-col">
      <form
        className="form-signin"
        onSubmit={ (e) => {
          e.preventDefault();

          onLogIn(email, password, rememberMe);
        }}
      >
        <ErrorMessage error={ error } />

        <div className="social-login">
          <button
            id="log-in-with-facebook"
            className="btn btn-blue btn-block"
            type="button"
            onClick={ onLogInWithFacebook }
          >
            <img
              src="/images/icon_fb.png"
              alt="Facebook"
            />

            ZALOGUJ SIĘ PRZEZ FACEBOOK
          </button>

          <button
            id="log-in-with-google"
            className="btn btn-white btn-block"
            type="button"
            onClick={ onLogInWithGoogle }
          >
            <img
              src="/images/icon_google.png"
              alt="Google"
            />

            ZALOGUJ SIĘ PRZEZ GOOGLE
          </button>
        </div>

        <div className="form-group my-2">
          <LabeledInput
            type="email"
            id="email"
            placeholder="e-mail"
            required
            value={ email }
            onChange={ setEmail }
            label="Adres e-mail"
          />
        </div>
        <div className="form-group my-2">
          <LabeledInput
            type="password"
            id="password"
            placeholder="hasło"
            required
            value={ password }
            onChange={ setPassword }
            label="Hasło"
          />
        </div>

        <Link
          to="/auth/forgot-password"
          className="green text-right d-block small"
        >
          Zapomniałeś hasła?
        </Link>

        <div className="custom-control custom-checkbox text-left mt-2">
          <LabeledInput
            type="checkbox"
            id="remember-me"
            className="custom-control-label"
            inputClassName="custom-control-input"
            value="remember-me"
            checked={ rememberMe }
            onChange={ setRememberMe }
            label="Zapamiętaj mnie"
          />
        </div>

        <LoadableButton
          className="btn btn-primary btn-block"
          type="submit"
          loading={ loading }
        >
          ZALOGUJ SIĘ
        </LoadableButton>
      </form>
    </div>
  );
}

LogInForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: ErrorMessage.propTypes.error,
  onLogIn: PropTypes.func.isRequired,
  onLogInWithFacebook: PropTypes.func.isRequired,
  onLogInWithGoogle: PropTypes.func.isRequired
};

export default LogInForm;
