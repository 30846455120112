import React from 'react';
import PropTypes from 'prop-types';

import { LoadableButton } from '../shared';

const LoadableActivator = ({ activator, loadActivatorText, loading, children, onLoadActivatorClick }) => {
  if (activator) {
    return children;
  }

  return (
    <LoadableButton
      className="btn btn-primary white ml-3"
      type="button"
      loading={ loading }
      onClick={ onLoadActivatorClick }
    >
      { loadActivatorText }
    </LoadableButton>
  );
}

LoadableActivator.propTypes = {
  activator: PropTypes.string,
  loadActivatorText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onLoadActivatorClick: PropTypes.func.isRequired
};

export default LoadableActivator;
