import { auth } from './config';
import { facebook, google } from './auth-providers';

export const signIn = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

export const signUp = async (email, password) => {
  await auth.createUserWithEmailAndPassword(email, password);

  await auth.currentUser.sendEmailVerification();
}

export const signOut = () =>
  auth.signOut();

export const onAuthStateChanged = (changed) =>
  auth.onAuthStateChanged(changed);

export const getToken = () =>
  auth.currentUser.getIdToken();

export const signInWithFacebook = () =>
  auth.signInWithRedirect(facebook);

export const signUpWithFacebook = () =>
  auth.signInWithRedirect(facebook);

export const signInWithGoogle = () =>
  auth.signInWithRedirect(google);

export const signUpWithGoogle = () =>
  auth.signInWithRedirect(google);

export const sendResetPasswordEmail = (email) =>
  auth.sendPasswordResetEmail(email);

export const confirmChangePassword = (code, password) =>
  auth.confirmPasswordReset(code, password);

export const confirmEmail = (code) =>
  auth.applyActionCode(code);

export const updatePassword = async (password) =>
  await auth.currentUser.updatePassword(password);

export const getRedirectResult = () =>
  auth.getRedirectResult();
