import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AuthUserContext from '../auth/AuthUserContext';
import { SignedInHeader, SignedOutHeader } from '../../components/shared';
import { operations } from '../../reducers/auth';

const Header = ({ onLogInClick, logOut }) => {
  const context = useContext(AuthUserContext);
  const { checking, user, authenticated } = context.authentication;

  if (checking) {
    return (
      <div>
        <p>Checking...</p>
      </div>
    );
  }

  if (authenticated) {
    return (
      <SignedInHeader
        name={ user.name }
        onLogOutClick={ logOut }
      />
    );
  }

  return (
    <SignedOutHeader
      onLogInClick={ onLogInClick }
    />
  );
}

Header.propTypes = {
  onLogInClick: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  logOut: operations.logOut
};

export default connect(null, mapDispatchToProps)(Header);
