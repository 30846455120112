import React from 'react';
import { connect } from 'react-redux';

import { operations, selectors } from '../../reducers/auth';
import { auth } from '../../firebase';
import { Spinner } from '../../components/shared';

import AuthUserContext from './AuthUserContext';

const withAuthenticationContainer = (Component) => {
  class WithAuthentication extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        checking: true
      };

      this._onAuthStateChanged = this._onAuthStateChanged.bind(this);
      this._isVerified = this._isVerified.bind(this);
    }

    componentDidMount() {
      const { cleanUpSession } = this.props;

      auth.getRedirectResult()
        .then((result) => {
          auth.onAuthStateChanged(this._onAuthStateChanged);
        })
        .catch((error) => {
          cleanUpSession(error);

          this.setState({
            checking: false
          });
        });
    }

    _onAuthStateChanged(authUser) {
      const { restoreSession, cleanUpSession } = this.props;

      if (authUser !== null) {
        if (this._isVerified(authUser)) {
          restoreSession(authUser);
        } else {
          cleanUpSession({
            code: 'auth/user-is-not-verified',
            message: 'User is not verified'
          });
        }
      } else {
        cleanUpSession();
      }

      this.setState({
        checking: false
      });
    }

    _isVerified(authUser) {
      const { providerId } = authUser.providerData[0];

      switch (providerId) {
        case 'facebook.com':
        case 'google.com':
          return true;

        case 'password':
          return authUser.emailVerified;

        default:
          return false;
      }
    }

    render() {
      const { user, authenticated } = this.props;

      if (this.state.checking) {
        return (
          <Spinner />
        );
      }

      return (
        <AuthUserContext.Provider value={{ authentication: { user, authenticated } }}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithAuthentication);
}

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
  authenticated: selectors.isLoggedIn(state)
});

const mapDispatchToProps = {
  restoreSession: operations.restoreSession,
  cleanUpSession: operations.logOut
};

export default withAuthenticationContainer;
