import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Details, LoadableActivator } from '.';
import { LoadableButton } from '../shared';

const DetailsWithLink = ({ id, link, title, loadingReport, loadingActivator, onLoadActivatorClick, onReportLinkClick, ...otherProps }) =>
  <Details
    { ...otherProps }
    title={ title }
    activator={
      <p className="green py-4 wrap">
        Twój link:&nbsp;

        <LoadableActivator
          activator={ link }
          loadActivatorText="Pokaż link"
          loading={ loadingActivator}
          onLoadActivatorClick={ onLoadActivatorClick }
        >
          <a
            href={ link }
            rel="noopener noreferrer"
            target="_blank"
            title={ title }
            className="green"
          >

            { link }
          </a>
        </LoadableActivator>
      </p>
    }
    report={
      <>
        {
          link &&
          <LoadableButton
            className="btn btn-secondary"
            type="button"
            loading={ loadingReport }
            onClick={ onReportLinkClick }
          >
            Zgłoś wadliwy link
          </LoadableButton>
        }
      </>
    }
    add={
      <Link
        to={`/offer/${ id }/add-link`}
        className="btn btn-fluorescent"
        role="button"
      >
        Dodaj swój link
      </Link>
    }
  />

DetailsWithLink.propTypes = {
  ...Details.propTypes,
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  loadingReport: PropTypes.bool.isRequired,
  loadingActivator: PropTypes.bool.isRequired,
  onLoadActivatorClick: PropTypes.func.isRequired,
  onReportLinkClick: PropTypes.func.isRequired
}

export default DetailsWithLink;
