import React from 'react';
import { connect } from 'react-redux';

import { Loadable } from '../../containers/shared';
import { Container } from '../../components/shared';
import { Create as CreateForm, CreatedConfirmation, SearchBar } from '../../components/offer';
import { operations } from '../../reducers/offer';

const Create = ({ location, create }) => {
  const { state } = location;

  if (state && state.created) {
    return (
      <Container>
        <CreatedConfirmation />
      </Container>
    );
  }

  return (
    <Loadable scope="offers">
      <Container>
        <SearchBar>
          Dodawanie oferty
        </SearchBar>

        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="offer offer-create p-5">
              <CreateForm onAddOffer={ create } />
            </div>
          </div>
        </div>
      </Container>
    </Loadable>
  );
}

const mapDispatchToProps = {
  create: operations.create
};

export default connect(null, mapDispatchToProps)(Create);
