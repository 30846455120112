import React from 'react';
import { Route } from 'react-router-dom';

import LearnMore from './LearnMore';
import QuestionsAndAnswers from './QuestionsAndAnswers';

const Routes = () =>
  <>
    <Route path="/learn-more" component={ LearnMore } />
    <Route path="/q-and-a" component={ QuestionsAndAnswers } />
  </>

export {
  QuestionsAndAnswers,
  Routes
};
