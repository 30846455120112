import React from 'react';
import { Link } from 'react-router-dom';

const Welcome = () =>
  <div className="col-lg-6 px-lg-5 grey-bg text-center align-self-end" id="welcome-col">
    <p className="big">Witaj w Refkody!</p>
    <img src="/images/refkody.svg" alt="Refkody"/>
    <p className="medium">
      Dołącz do naszej społeczności i korzystaj z bonusów. Dziel się kodami polecającymi i referencyjnymi.
    </p>

    <Link
      to="/learn-more"
      className="green d-block medium mb-3"
    >
      Dowiedz się więcej
    </Link>

    <Link
      to="/auth/register"
      className="btn btn-primary d-block"
    >
      ZAREJESTRUJ SIĘ
    </Link>
  </div>

export default Welcome;
