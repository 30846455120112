import React from 'react';
import { Link } from 'react-router-dom';

const CreatedConfirmation = () =>
  <div className="row">
    <div className="col-lg-8 offset-lg-2">
      <div className="basic-page p-md-5 p-3 mt-5 text-center">
        <p>
          Oferta została dodana. Po akceptacji przez moderatora pojawi się na liście ofert.
        </p>
        <div className="row pt-3">
          <div className="col-md-12 text-center mb-2">
            <Link
              to="/offers/popular"
              className="btn btn-primary px-5 mt-2"
              role="button"
            >
              OK
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>

export default CreatedConfirmation;
