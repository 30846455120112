import React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { ChangePassword } from '../../components/auth';
import { operations } from '../../reducers/auth';
import { selectors as uiSelectors } from '../../reducers/ui';

const ChangePasswordContainer = ({ location, loading, error, changePassword }) => {
  const { state } = location;

  if (state && state.params && state.params.oobCode) {
    const code = state.params.oobCode;

    return (
      <Container>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="p-5">
              <ChangePassword
                loading={ loading }
                error={ error }
                onChangePassword={ (password) => changePassword(code, password) } />
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="p-5">
            <div className="basic-page p-md-5 p-3 text-center">
              <p className="error">
                Wystąpił błąd podczas zmiany hasła. Upewnij się, że link bangla :)
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

const ui = uiSelectors.forScope('auth');

const mapStateToProps = (state) => ({
  loading: ui.isLoading(state),
  error: ui.getError(state)
});

const mapDispatchToProps = {
  changePassword: operations.changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordContainer);
