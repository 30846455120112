import React from 'react';

import { Search } from '.';
import { GoBack } from '../shared';

const SearchBar = ({ children }) =>
  <div className="row">
    <div className="col-12 p-1">
      <div className="offer-nav my-3 py-3">
        <div className="row">
          <div className="col-xl-8 col-md-6 align-self-center">
            <p className="uppercase px-4">
              <GoBack />

              { children }
            </p>
          </div>

          <Search />
        </div>
      </div>
    </div>
  </div>

export default SearchBar;
