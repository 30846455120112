import React from 'react';
import PropTypes from 'prop-types';

import { StatefulList } from '.';

const NavigationTab = ({ id, active, offers }) =>
  <div
    className={`tab-pane fade show ${ active && 'active' }`}
    id={ id }
    role="tabpanel"
    aria-labelledby={ `${id}-tab` }
  >
    <div className="row">
      <StatefulList
        offers={ offers }
        emptyMessage="Brak ofert w tej kategorii"
      />
    </div>
  </div>

NavigationTab.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool,
  offers: PropTypes.array.isRequired
};

NavigationTab.defaultProps = {
  active: false
};

export default NavigationTab;
