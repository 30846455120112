import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import { Input, TextArea } from '../common';
import { UploadFile } from '../shared';

import "react-datepicker/dist/react-datepicker.css";

const Create = ({ onAddOffer }) => {
  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ terms, setTerms ] = useState('');
  const [ deadline, setDeadline ] = useState(null);
  const [ type, setType ] = useState('link');
  const [ link, setLink ] = useState('');
  const [ code, setCode ] = useState('');
  const [ codePage, setCodePage ] = useState('');
  const [ bonus, setBonus ] = useState('');
  const [ file, setFile ] = useState('');

  return (
    <form
      id="form-offer-create"
      onSubmit={ (e) => {
        e.preventDefault();

        onAddOffer({ title, description, terms, deadline, type, link, code, codePage, bonus, file });
      }}
    >
      <div className="row pb-3">
        <div className="col-sm-12">
          <div className="input-group mb-4">
            <div className="custom-file">
              <UploadFile
                label="* Dodaj grafikę"
                className="custom-file-input"
                labelClassName="custom-file-label"
                id="offer-file"
                onFileSelected={ setFile }
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="offer-title">* Adres strony lub nazwa serwisu</label>
            <i className="fas fa-pen-square"></i>

            <Input
              type="text"
              id="offer-title"
              required
              value={ title }
              onChange={ setTitle }
            />
          </div>
          <div className="form-group">
            <span className="green huge fluorescent bold">* Bonus</span>

            <TextArea
              id="offer-bonus"
              rows={ 1 }
              placeholder="Opisz bonus. W przypadku bonusu pieniężnego wpisz kwotę oraz walutę. Ta informacja będzię widoczna na stronie startowej Refkody.pl"
              value={ bonus }
              onChange={ setBonus }
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer-desc">* Opisz warunki uzyskania bonusa</label>
            <i className="fas fa-file-alt"></i>

            <TextArea
              id="offer-desc"
              rows={ 1 }
              value={ description }
              onChange={ setDescription }
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer-terms">Link do szczegółowych warunków bonusa</label>
            <i className="fas fa-link"></i>

            <Input
              type="text"
              id="offer-terms"
              value={ terms }
              onChange={ setTerms }
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer_date">Termin ważności</label>
            <i className="far fa-calendar-alt"></i>

            <DatePicker
              className="form-control"
              selected={ deadline }
              dateFormat="dd-MM-yyyy"
              minDate={ new Date() }
              fixedHeight={ true }
              onChange={ (date) => setDeadline(date) }
            />
          </div>
          <div className="form-group">
            <label htmlFor="offer-type">* Typ oferty</label>
            <i className="far fa-folder-open offer-type"></i>

            <div className="custom-control custom-radio mt-2">
              <Input
                type="radio"
                id="offer-type-link"
                value="link"
                checked={ type === 'link' }
                onChange={ setType }
                className="custom-control-input"
              />

            <label className="custom-control-label" htmlFor="offer-type-link">Link aktywujący</label>
            </div>
            <div className="custom-control custom-radio">
              <Input
                type="radio"
                id="offer-type-code"
                value="code"
                checked={ type === 'code' }
                onChange={ setType }
                className="custom-control-input"
              />

            <label className="custom-control-label" htmlFor="offer-type-code">Kod</label>
            </div>
          </div>

          {
            type === 'link' &&
            <div className="form-group">
              <label htmlFor="offer-link">* Link aktywujący promocję</label>
              <i className="fas fa-link"></i>

              <Input
                type="url"
                id="offer-link"
                required
                value={ link }
                onChange={ setLink }
              />
            </div>
          }

          {
            type === 'code' &&
            <>
              <div className="form-group">
                <label htmlFor="offer-code">* Kod promocyjny</label>
                <i className="fas fa-tags"></i>

                <Input
                  type="text"
                  id="offer-code"
                  required
                  value={ code }
                  onChange={ setCode }
                />
              </div>
              <div className="form-group">
                <label htmlFor="offer-code-link">* Link do strony gdzie należy wpisać kod</label>
                <i className="fas fa-link"></i>

                <Input
                  type="text"
                  id="offer-code-link"
                  placeholder="Nie podawaj linków zawierających Twój kod referencyjny"
                  required
                  value={ codePage }
                  onChange={ setCodePage }
                />
              </div>
            </>
          }
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12 text-right">
          <button className="btn btn-secondary" type="submit">
            Dodaj ofertę
          </button>
        </div>
      </div>
    </form>
  );
}

Create.propTypes = {
  onAddOffer: PropTypes.func.isRequired
};

export default Create;
