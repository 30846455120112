import React from 'react';
import { Route } from 'react-router-dom';

import ContactForm from './ContactForm';

const Routes = () =>
  <>
    <Route exact path="/contact" component={ ContactForm } />
  </>

export {
  ContactForm,
  Routes
};
