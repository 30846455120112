import React from 'react';
import PropTypes from 'prop-types';

import { Search } from '.';

const Navigation = ({ selected }) =>
  <div className="offer-nav my-3">
    <div className="row row-nav-items">
      <div className="col-xl-8 col-md-6 align-self-center">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          {
            /*
            Temporarly disable Hot Deals and New arrivals

            <NavigationItem
              id="hot-deals"
              link="/offers/hot-deals"
              active={ selected === 'hot-deals' }
            >
              Hot deals
            </NavigationItem>

            <NavigationItem
              id="new-arrivals"
              link="/offers/new-arrivals"
              active={ selected === 'new-arrivals' }
            >
              New arrivals
            </NavigationItem>
            */

            /*
            Temporarly disable Popular (no need to display only one category)
            <NavigationItem
              id="popular"
              link="/offers/popular"
              active={ selected === 'popular' }
            >
              Popular
            </NavigationItem>
            */
          }
        </ul>
      </div>

      <Search />
    </div>
  </div>

Navigation.propTypes = {
  selected: PropTypes.oneOf(['hot-deals', 'new-arrivals', 'popular'])
};

Navigation.defaultProps = {
  selected: 'hot-deals'
};

export default Navigation;
