import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, TextArea } from '../common';

const Form = ({ email, onSendForm }) => {
  const [ from, setFrom ] = useState(email);
  const [ message, setMessage ] = useState('');

  return (
    <form
      id="form-contact"
      onSubmit={ (e) => {
        e.preventDefault();

        onSendForm(from, message);
      }}
    >
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="form-group">
            <label htmlFor="contact-from" className="mb-2">Adres e-mail</label>

            <Input
              type="text"
              id="contact-from"
              required
              value={ from }
              onChange={ setFrom }
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-message" className="mb-2">Wiadomość</label>

            <TextArea
              id="contact-message"
              required
              value={ message }
              onChange={ setMessage }
            />
          </div>
          <div className="text-center">
            <button className="btn btn-primary mt-2" type="submit">
              Wyślij wiadomość
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

Form.propTypes = {
  email: PropTypes.string,
  onSendForm: PropTypes.func.isRequired
};

Form.defaultProps = {
  email: ''
};

export default Form;
