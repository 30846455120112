import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { Container } from '../../components/shared';
import { operations, selectors } from '../../reducers/offer';
import { SearchBar, StatefulList } from '../../components/offer';
import { Loadable } from '../../containers/shared';

export const SearchResults = ({ location, offers, search }) => {
  const params = queryString.parse(location.search);

  useEffect(() => { search(params.q); }, [params.q]);

  return (
    <Container>
      <SearchBar>
        Oferty dla <b>{ params.q }</b>
      </SearchBar>

      <Loadable scope="offers">
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              <div className="row">
                <StatefulList
                  offers={ offers }
                  emptyMessage="Nie znaleziono żadnych ofert pasujących do frazy"
                />
              </div>
            </div>
          </div>
        </div>
      </Loadable>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  offers: selectors.getSearchResults(state)
});

const mapDispatchToProps = {
  search: operations.search
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
