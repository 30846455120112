import { createSelector } from 'reselect';

export const getUser = (state) => state.auth.user || {};

export const isLoggedIn = createSelector(
  [ getUser ],
  (user) => user.uid !== undefined
);

export const getUserId = createSelector(
  [ getUser ],
  (user) => user.uid
);

export const getUserEmail = createSelector(
  [ getUser ],
  (user) => user.email
);
