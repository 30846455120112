import React from 'react';
import PropTypes from 'prop-types';

class Input extends React.PureComponent {

  constructor(props) {
    super(props);

    this._onValueChanged = this._onValueChanged.bind(this);
  }

  _onValueChanged(event) {
    const {
      type,
      checked,
      onChange
    } = this.props;

    if (type === 'checkbox') {
      onChange(!checked);
    } else {
      onChange(event.target.value);
    }
  }

  render() {
    const {
      type,
      id,
      className,
      placeholder,
      required,
      value,
      checked,
      min,
      max
    } = this.props;

    return (
      <input
        type={ type }
        id={ id }
        className={ className }
        placeholder={ placeholder }
        required={ required }
        value={ value }
        checked={ checked }
        min={ min }
        max={ max }
        onChange={ this._onValueChanged }
      />
    );
  }
}

Input.propTypes = {
  type: PropTypes.oneOf(['email', 'password', 'checkbox', 'text', 'number', 'url', 'radio', 'search']).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

Input.defaultProps = {
  id: '',
  className: 'form-control',
  placeholder: '',
  required: false,
  checked: false
};

export default Input;
