import React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { ForgotPassword } from '../../components/auth';
import { operations } from '../../reducers/auth';
import { selectors as uiSelectors } from '../../reducers/ui';

const ui = uiSelectors.forScope('auth');

const ForgotPasswordContainer = ({ loading, error, resetPassword }) =>
  <Container>
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="p-5">
          <ForgotPassword
            loading={ loading }
            error={ error }
            onResetPassword={ resetPassword }
          />
        </div>
      </div>
    </div>
  </Container>

const mapStateToProps = (state) => ({
  loading: ui.isLoading(state),
  error: ui.getError(state)
});

const mapDispatchToProps = {
  resetPassword: operations.resetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
