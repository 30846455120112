import * as types from './types';

export const fetchedOffers = (offers) => ({
  type: types.FETCHED,
  payload: {
    offers
  }
});

export const fetchedOfferDetails = (offer) => ({
  type: types.FETCHED_DETAILS,
  payload: {
    offer
  }
});

export const fetchedSearchResults = (results) => ({
  type: types.FETCHED_SEARCH_RESULTS,
  payload: {
    results
  }
});

export const fetchedStatistics = (statistics) => ({
  type: types.FETCHED_STATISTICS,
  payload: {
    statistics
  }
});

export const fetchedActivator = (id, activator) => ({
  type: types.FETCHED_ACTIVATOR,
  payload: {
    id,
    activator
  }
});
