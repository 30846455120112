import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { LogInModal } from '../../containers/auth';
import AuthUserContext from '../../containers/auth/AuthUserContext';

const Login = ({ location, history }) => {
  const { authenticated } = useContext(AuthUserContext).authentication;

  if (authenticated) {
    const { from } = location.state || { from: { pathname: '/' } };

    return <Redirect to={ from } />
  }

  return (

    <LogInModal
      visible={ true }
      onCloseClick={ () => history.push({ pathname: '/' }) }
    />
  );
}

export default Login;
