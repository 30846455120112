import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import AuthUserContext from '../auth/AuthUserContext';
import { LogInModal } from '../../components/auth';
import { operations } from '../../reducers/auth';
import { selectors as uiSelectors } from '../../reducers/ui';

const LogInModalContainer = ({ logIn, logInWithFacebook, logInWithGoogle, visible, onCloseClick, ...otherProps }) => {
  const { authenticated } = useContext(AuthUserContext).authentication;

  useEffect(() => {
    if (authenticated) {
      onCloseClick();
    }
  });

  return (
    <LogInModal
      { ...otherProps }
      onCloseClick={ onCloseClick }
      visible={ visible }
      onLogIn={ logIn }
      onLogInWithFacebook={ logInWithFacebook }
      onLogInWithGoogle={ logInWithGoogle }
    />
  );
}

LogInModalContainer.propTypes = {
  ...LogInModal.propTypes
};

const ui = uiSelectors.forScope('auth');

const mapStateToProps = (state) => ({
  loading: ui.isLoading(state),
  error: ui.getError(state)
});

const mapDispatchToProps = {
  logIn: operations.logInWithEmailAndPassword,
  logInWithFacebook: operations.logInWithFacebook,
  logInWithGoogle: operations.logInWithGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInModalContainer);
