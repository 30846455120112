function AuthException(code, message) {
  this.code = code;
  this.message = message;
  this.name = "AuthException";
}

AuthException.prototype.toString = function () {
  return `${this.name}: [${this.code}] ${this.message}`;
}

function ValidationException(code, message) {
  this.code = code;
  this.message = message;
  this.name = "ValidationException";
}

ValidationException.prototype.toString = function () {
  return `${this.name}: [${this.code}] ${this.message}`;
}

export {
  AuthException,
  ValidationException
};
