import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavigationItem = ({ id, link, active, children }) =>
  <li className="nav-item">
    <Link
      to={ link }
      className={ `nav-link ${ active && 'active' }` }
      aria-controls={ id }
      aria-selected={ active }
    >
      { children }
    </Link>
  </li>

NavigationItem.propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.bool
};

NavigationItem.defaultProps = {
  active: false
};

export default NavigationItem;
