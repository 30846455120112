import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthUserContext from './AuthUserContext';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const context = useContext(AuthUserContext);
  const { checking, authenticated } = context.authentication;

  if (checking) {
    return null;
  }

  return (
    <Route
      { ...otherProps }
      render={
        (props) => {
          if (authenticated) {
            return <Component { ...props } />;
          }

          return <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: props.location }
            }}
          />;
        }
      }
    />
  );
}

export default PrivateRoute;
