import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage, Spinner } from '.';

const wrapWithDiv = (className) =>
  (Component) => {
    if (className.length > 0) {
      return (
        <div className={ className }>
          { Component }
        </div>
      );
    }

    return Component;
  }

const Loadable = ({ loading, error, className, showContentWhenFailed, children }) => {
  if (loading) {
    return <Spinner />;
  }

  if (error) {
    if (showContentWhenFailed) {
      return wrapWithDiv(className)(
        <>
          <ErrorMessage error={ error } />

          { children }
        </>
      );
    }

    return (
      <ErrorMessage error={ error } />
    );
  }

  return wrapWithDiv(className)(children);
};

Loadable.propTypes = {
  ...ErrorMessage.propTypes,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  showContentWhenFailed: PropTypes.bool
};

Loadable.defaultProps = {
  className: '',
  showContentWhenFailed: false
};

export default Loadable;
