import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Loadable } from '../../components/shared';
import { selectors } from '../../reducers/ui';

const LoadableContainer = ({ children, ...otherProps }) =>
  <Loadable { ...otherProps }>
    { children }
  </Loadable>

const mapStateToProps = (state, ownProps) => ({
  loading: selectors.isLoading(state, ownProps),
  error: selectors.getError(state, ownProps)
});

LoadableContainer.propTypes = {
  scope: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(LoadableContainer);
