import { createSelector } from 'reselect';

const getOffers = (state) => state.offer.offers || [];

const getStatistics = (state) => state.offer.statistics || {};

export const getHotDeals = createSelector(
  [ getOffers ],
  (offers) => offers.filter(({ isHotDeal }) => isHotDeal)
);

export const getNewArrivals = createSelector(
  [ getOffers ],
  (offers) => offers.filter(({ isNew }) => isNew)
);

export const getPopular = createSelector(
  [ getOffers, getStatistics ],
  (offers, statistics) =>
    offers.map((offer) => {
      const stats = statistics[offer.id] || { views: 0 };

      return {
        ...offer,
        ...stats
      }
    })
    .sort((left, right) => right.views - left.views)
);

export const getById = (state, { id }) => state.offer.byId[id] || null;

export const getSearchResults = (state) => state.offer.searchResults || [];

export const hasCachedOffers = createSelector(
  [ getOffers ],
  (offers) => offers.length > 0
);

export const getActivator = (state, { id }) => state.offer.activators[id] || '';
