import React from 'react';

const Container = ({ children }) =>
  <main className="main-page pb-5">
    <div className="container">
      { children }
    </div>
  </main>

export default Container;
