import React, { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pl from 'date-fns/locale/pl';

import { LogInModal, withAuthentication } from './containers/auth';
import { Header } from './containers/shared';
import { PageViewTracker } from './containers/tracking';
import { NotFound } from './screens/shared';
import { Routes } from './screens';
import history from './history';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const [ logInVisible, setLogInVisible ] = useState(false);

  useEffect(() => history.listen(() => setLogInVisible(false)));
  useEffect(() => {
    registerLocale('pl', pl);
    setDefaultLocale('pl');
  });

  return (
    <Router history={ history }>
      <div className="App">
        <Header
          onLogInClick={ () => setLogInVisible(true) }
        />

        <PageViewTracker />

        <Switch>
          <Routes />

          <Route component={ NotFound } />
        </Switch>

        <LogInModal
          visible={ logInVisible }
          onCloseClick={ () => setLogInVisible(false) }
        />
      </div>
    </Router>
  );
}

export default withAuthentication(App);
