import React, { useState} from 'react';
import PropTypes from 'prop-types';

import { Input } from '../common';

const AddCode = ({ logo, title, onAddCodeClick }) => {
  const [ code, setCode ] = useState('');

  return (
    <form
      id="form-offer-add-code"
      onSubmit={ (e) => {
        e.preventDefault();

        onAddCodeClick(code);
      }}
    >
      <div className="row">
        <div className="col-sm-6 align-self-end">
          <img
            src={ logo }
            alt={ title }
          />
        </div>
      </div>
      <div className="py-3">
        <h3 className="my-2">{ title }</h3>
        <div className="row">
          <div className="col-md-8 align-self-end">
            <div className="form-group mt-4">
             <label htmlFor="offer-link">Kod promocyjny</label>
             <i className="fas fa-tags"></i>

             <Input
                type="text"
                id="offer-code"
                required
                value={ code }
                onChange={ setCode }
             />
            </div>
          </div>
          <div className="col-md-4 text-right align-self-end">
            <button className="btn btn-secondary d-block w-100" type="submit">
              Dodaj kod
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

AddCode.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onAddCodeClick: PropTypes.func.isRequired
};

export default AddCode;
