import React from 'react';
import PropTypes from 'prop-types';

const LoadableButton = ({ className, type, loading, disabled, children, onClick }) =>
  <button
    className={ className }
    type={ type }
    disabled={ loading || disabled }
    onClick={ onClick }
  >
    {
      loading &&
      <img
        src="/images/spinner-white.gif"
        alt="Loading"
        className="image-spinner"
      />
    }

    { children }
  </button>

LoadableButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']).isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

LoadableButton.defaultProps = {
  className: '',
  disabled: false
};

export default LoadableButton;
