import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { LabeledInput } from '../common';
import { ErrorMessage, LoadableButton } from '../shared';

const ChangePassword = ({ loading, error, onChangePassword }) => {
  const [ password, setPassword ] = useState('');
  const [ repeatPassword, setRepeatPassword ] = useState('');

  return (
    <div className="basic-page p-md-5 p-3 text-center">
      <p>Wpisz nowe hasło</p>

      <form
        className="mt-3"
        onSubmit={ (e) => {
          e.preventDefault();

          onChangePassword(password);
        }}
      >
        <ErrorMessage error={ error } />

        <div className="form-group my-2">
          <LabeledInput
            type="password"
            id="new-password"
            placeholder="nowe hasło"
            required
            value={ password }
            onChange={ setPassword }
            label="Nowe hasło"
          />
        </div>

        <div className="form-group my-2">
          <LabeledInput
            type="password"
            id="repeat-password"
            placeholder="powtórz nowe hasło"
            required
            value={ repeatPassword }
            onChange={ setRepeatPassword }
            label="Powtórz nowe hasło"
          />
        </div>

        <LoadableButton
          className="btn btn-primary btn-block"
          type="submit"
          disabled={ password.length < 6 || password !== repeatPassword }
          loading={ loading }
        >
          Zmień hasło
        </LoadableButton>
      </form>
    </div>
  );
}

ChangePassword.propTypes = {
  ...ErrorMessage.propTypes,
  loading: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired
};

export default ChangePassword;
