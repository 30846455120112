import React from 'react';
import PropTypes from 'prop-types';

import { SnapshotWithCode, SnapshotWithLink } from '.';

const List = ({ offers }) =>
  offers.map(
    (offer) =>
    <div
      className="col-xl-3 col-lg-4 col-sm-6 col-12 p-1"
      key={ offer.id }
    >
      {
        offer.type === 'code' &&
        <SnapshotWithCode { ...offer } />
      }

      {
        offer.type === 'link' &&
        <SnapshotWithLink { ...offer} />
      }
    </div>
  );

List.propTypes = {
  offers: PropTypes.array.isRequired
};

export default List;
