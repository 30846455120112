import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import reducer from './root';
import redirect from './redirect/middleware';
import tracking from './tracking/middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const applyMiddlewares = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(
      thunk,
      redirect,
      tracking
    );
  }

  return applyMiddleware(
    thunk,
    logger,
    redirect,
    tracking
  );
};

const configureStore = () =>
  createStore(
    reducer,
    composeEnhancers(applyMiddlewares())
  );

export default configureStore;
