import React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { Profile } from '../../components/auth';
import { operations, selectors } from '../../reducers/auth';

const ProfileContainer = ({ user, logOut }) =>
  <Container>
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="p-5">
          <Profile
            name={ user.name }
            onLogOutClick={ logOut }
          />
        </div>
      </div>
    </div>
  </Container>

const mapStateToProps = (state) => ({
  user: selectors.getUser(state)
});

const mapDispatchToProps = {
  logOut: operations.logOut
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
