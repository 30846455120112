import React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { ChangePassword } from '../../components/auth';
import { operations } from '../../reducers/auth';
import { selectors as uiSelectors } from '../../reducers/ui';

const UpdatePasswordContainer = ({ loading, error, updatePassword }) =>
  <Container>
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="p-5">
          <ChangePassword
            loading={ loading }
            error={ error }
            onChangePassword={ updatePassword } />
        </div>
      </div>
    </div>
  </Container>

const ui = uiSelectors.forScope('auth');

const mapStateToProps = (state) => ({
  loading: ui.isLoading(state),
  error: ui.getError(state)
});

const mapDispatchToProps = {
  updatePassword: operations.updatePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordContainer);
