import React from 'react';

import { Container } from '../../components/shared';

const VerificationEmailSent = ({ location }) => {
  const { state } = location;

  if (state && state.email) {
    return (
      <Container>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="basic-page p-md-5 p-3 mt-5 text-center">
              <p>
                Wysłaliśmy e-mail potwierdzający na <b>{ state.email }</b> - kliknij na link przygotowany dla Ciebie.
              </p>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="basic-page p-md-5 p-3 mt-5 text-center">
            <p className="error">
              Wystąpił błąd podczas weryfikacji konta.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default VerificationEmailSent;
