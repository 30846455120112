import React from 'react';
import { Route } from 'react-router-dom';

import NotFound from './NotFound';

const Routes = () =>
  <>
    <Route path="/not-found" component={ NotFound } />
  </>

export {
  NotFound,
  Routes
};
