import React, { useState } from 'react';
import { withRouter } from 'react-router';

import { LabeledInput } from '../common';

export const Search = ({ history }) => {
  const [ query, setQuery ] = useState('');

  return (
    <div className="col-xl-4 col-md-6 align-self-center text-md-right text-center">
        <form
          onSubmit={ (e) => {
            e.preventDefault();

            history.push(`/offers/search?q=${query}`);
          }}
        >
          <LabeledInput
            type="search"
            id="search"
            value={ query }
            onChange={ setQuery }
            label="Szukaj"
          />
        </form>
    </div>
  );
}

export default withRouter(Search);
