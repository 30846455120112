import React from 'react';
import PropTypes from 'prop-types';

const Details = ({ logo, bonus, title, description, terms, deadline, activator, report, add }) =>
  <div className="offer offer-details p-5">
    <div className="row">
      <div className="col-sm-6 align-self-end">
        <img
          src={ logo }
          alt={ title }
        />
      </div>
      <div className="col-sm-6 text-sm-right">
        <h3 className="my-2 wrap">{ title }</h3>
      </div>
    </div>
    <div className="py-3">
      {
        bonus &&
        <p className="green huge fluorescent bold">
          Bonus: { bonus }
        </p>
      }

      <h5 className="pt-2">Warunki uzyskania bonusa</h5>
      <p className="pb-1 honor-white-space">
        { description }
      </p>

      {
        terms &&
        <>
          <h5 className="pt-2">Link do szczegółowych warunków bonusa</h5>
          <p className="pb-1 wrap">
            <a
              href={ terms }
              target="_blank"
              rel="noopener noreferrer"
              className="green"
            >
              { terms }
            </a>
          </p>
        </>
      }

      {
        deadline &&
        <p className="pt-2">
          <span className="semibold">Termin ważności:</span> <em>{ deadline }</em>
        </p>
      }

      { activator }
    </div>
    <div className="row pt-3">
      <div className="col-md-6 col-12 text-md-left text-center mb-2">
        { report }
      </div>
      <div className="col-md-6 col-12 text-md-right text-center mb-2">
        { add }
      </div>
    </div>
  </div>

Details.propTypes = {
  logo: PropTypes.string.isRequired,
  bonus: PropTypes.string,
  otherBonus: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  terms: PropTypes.string,
  deadline: PropTypes.string,
  activator: PropTypes.element,
  report: PropTypes.element,
  add: PropTypes.element
};

Details.defaultProps = {
  bonus: '',
  terms: '',
  deadline: null,
  onlyForNewUsers: false
};

export default Details;
