import React from 'react';
import { connect } from 'react-redux';

import { PageViewTracker } from '../../components/tracking';
import { actions } from '../../reducers/tracking';

const PageViewTrackerContainer = ({ trackPageView }) =>
  <PageViewTracker
    onLocationChanged={ trackPageView }
  />

const mapDispatchToProps = {
  trackPageView: actions.trackPageView
};

export default connect(null, mapDispatchToProps)(PageViewTrackerContainer);
