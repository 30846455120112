const get = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: headers(token)
  })
  .then(checkResponse);

const post = (url, token, params) =>
  fetch(url, {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify(params)
  })
  .then(checkResponse);

const headers = (token, override = {}) => {
  let base = {
    'Content-Type': 'application/json'
  };

  if (token) {
     base = {
        ...base,
        'Authorization': `Bearer ${token}`,
     }
  }

  return { ...base, override };
};

const checkResponse = async (response) => {
  try {
    if (response.ok) {
      return await response.json();
    }

    console.error('Error reponse: ', response);

    throw new Error('rest/failure');
  } catch (error) {
    if (response.status === 401) {
      console.error('Error - unauthorized');

      throw new Error('auth/unauthorized');
    }

    console.error('There was an error while perforimg request, error: ', error);

    throw error; // to not break a chain
  }
};

export const create = (baseUrl) => ({
  get: (url, token) => get(baseUrl + url, token),
  post: (url, token, params) => post(baseUrl + url, token, params)
});
