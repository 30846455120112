import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Input, LabeledInput } from '../common';
import { ErrorMessage, LoadableButton } from '../shared';

const Register = ({ loading, error, onRegister, onRegisterWithFacebook, onRegisterWithGoogle }) => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ repeatPassword, setRepeatPassword ] = useState('');
  const [ areRulesAccepted, setRulesAccepted ] = useState(false);

  return (
    <form
      className="form-signin mt-3"
      onSubmit={ (e) => {
        e.preventDefault();

        onRegister(email, password, repeatPassword);
      }}
    >
      <ErrorMessage error={ error } />

      <div className="form-group my-2">
        <LabeledInput
          type="email"
          id="email"
          placeholder="e-mail"
          required
          value={ email }
          onChange={ setEmail }
          label="Adres e-mail"
        />
      </div>
      <div className="form-group my-2">
        <LabeledInput
          type="password"
          id="password"
          placeholder="hasło"
          required
          value={ password }
          onChange={ setPassword }
          label="Hasło"
        />
      </div>
      <div className="form-group my-2">
        <LabeledInput
          type="password"
          id="password-repeat"
          placeholder="powtórz hasło"
          required
          value={ repeatPassword }
          onChange={ setRepeatPassword }
          label="Powtórz hasło"
        />
      </div>
      <div className="custom-control custom-checkbox text-left mt-3">
        <Input
          type="checkbox"
          id="rules"
          className="custom-control-input"
          value="accept"
          checked={ areRulesAccepted }
          onChange={ setRulesAccepted }
        />

        <label
          htmlFor="rules"
          className="custom-control-label"
        >
          Zapoznałem się i akceptuję <a href="/terms-and-conditions.pdf" target="_blank">Regulamin serwisu</a> oraz <a href="/rodo.pdf" target="_blank">Politykę Prywatności</a>.
        </label>
      </div>

      <LoadableButton
        className="btn btn-primary btn-block"
        type="submit"
        disabled={ !areRulesAccepted }
        loading={ loading }
      >
        ZAREJESTRUJ SIĘ
      </LoadableButton>

      <div className="social-login">
        <button
          className="btn btn-blue btn-block"
          type="button"
          id="register-with-facebook"
          disabled={ !areRulesAccepted }
          onClick={ onRegisterWithFacebook }
        >
          <img
            src="/images/icon_fb.png"
            alt="Facebook"
          />

          ZAREJESTRUJ SIĘ PRZEZ FACEBOOK
        </button>

        <button
          className="btn btn-white btn-block"
          type="button"
          id="register-with-google"
          disabled={ !areRulesAccepted }
          onClick={ onRegisterWithGoogle }
        >
          <img
              src={ areRulesAccepted ? "/images/icon_google.png" : "/images/icon_google_white.png" }
              alt="Google"
          />

          ZAREJESTRUJ SIĘ PRZEZ GOOGLE
        </button>
      </div>
    </form>
  );
}

Register.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: ErrorMessage.propTypes.error,
  onRegister: PropTypes.func.isRequired,
  onRegisterWithFacebook: PropTypes.func.isRequired,
  onRegisterWithGoogle: PropTypes.func.isRequired
};

export default Register;
