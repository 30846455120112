import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SignedInHeader = ({ name }) =>
  <header>
    <div className="container">
      <div className="row">
        <div className="col-sm-4 align-self-center">
          <Link to="/" title="Refkody">
            <img src="/images/logo.svg" alt="Refkody"/>
          </Link>
        </div>
        <div className="col-sm-8 align-self-center">
          <nav className="nav nav-masthead">
            <p>
              Cześć&nbsp;
              <Link
                to="/auth/profile"
              >
                <strong>{ name }</strong>
              </Link>
            </p>

            <Link
              to="/offer/new"
              className="nav-link outline"
            >
              Dodaj ofertę
            </Link>
            <Link className="btn-primary ml-2" to="/contact">
              Kontakt
            </Link>
          </nav>
        </div>
      </div>
    </div>
  </header>

SignedInHeader.propTypes = {
  name: PropTypes.string.isRequired
};

export default SignedInHeader;
