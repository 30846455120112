import * as types from './types';

const INITIAL_STATE = {
  offers: [],
  statistics: {},
  byId: {},
  activators: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCHED:
      return {
        ...state,
        offers: [ ...action.payload.offers ],
        byId: {
          ...action.payload.offers.reduce((map, offer) => {
            map[offer.id] = offer;

            return map;
          }, {})
        }
      };

    case types.FETCHED_DETAILS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.offer.id]: action.payload.offer
        }
      };

    case types.FETCHED_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [ ...action.payload.results ]
      };

    case types.FETCHED_STATISTICS:
      return {
        ...state,
        statistics: {
          ...action.payload.statistics.reduce((map, stats) => {
            map[stats.id] = stats;

            return map;
          }, {})
        }
      };

    case types.FETCHED_ACTIVATOR:
      return {
        ...state,
        activators: {
          ...state.activators,
          [action.payload.id]: action.payload.activator
        }
      };

    default:
      return state;
  }
};

export default reducer;
