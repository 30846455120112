import React from 'react';
import { Link } from 'react-router-dom';

import { Snapshot } from '.';

const SnapshotWithLink = ({ id, ...otherProps }) =>
  <Snapshot
    { ...otherProps }
    id={ id }
    add={
      <Link
        to={`/offer/${ id }/add-link`}
        className="btn btn-secondary"
        role="button"
      >
        Dodaj swój link
      </Link>
    }
  />

SnapshotWithLink.propTypes = {
  ...Snapshot.propTypes
};

export default SnapshotWithLink;
