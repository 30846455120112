import React from 'react';
import PropTypes from 'prop-types';

import errors from './errors.json';

class ErrorMessage extends React.PureComponent {

  renderError(error) {
    if (error.code && error.message) {
      return errors[error.code] || error.message;
    }

    if (typeof error === 'string') {
      return error;
    }

    console.error('Unsupported error type: ', error);

    return 'There was an error';
  }

  render() {
    const { error } = this.props;

    if (!error) {
      return null;
    }

    return (
      <div className="error">
        <p>{ this.renderError(error) }</p>
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default ErrorMessage;
