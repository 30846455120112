import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LogInForm, Welcome } from '.';

const LogInModal = ({ history, visible, onCloseClick, ...otherProps }) =>
  <Modal
    size="lg"
    show={ visible }
    onHide={ onCloseClick }
    centered="centered"
    id="login-popup"
  >
    <Modal.Body>
      <button type="button" className="close" onClick={ onCloseClick }>
        <span aria-hidden="true">&times;</span>
      </button>

      <div className="row m-0">
        <Welcome />

        <LogInForm { ...otherProps } />
      </div>
    </Modal.Body>
  </Modal>

LogInModal.propTypes = {
  ...LogInForm.propTypes,
  visible: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired
};

export default withRouter(LogInModal);
