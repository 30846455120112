import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { Navigation, NavigationTab } from '../../components/offer';
import { Loadable } from '../../containers/shared';
import { operations, selectors } from '../../reducers/offer';

const Offers = ({ match, hotDeals, newArrivals, popular, fetchOffers }) => {
  useEffect(() => { fetchOffers(); }, []);

  const { category } = match.params;

  return (
    <Container>
      <div className="row">
        <div className="col-12 p-1">
          <Navigation
            selected={ category }
          />
        </div>
      </div>

      <Loadable scope="offers">
        <div className="row">
          <div className="col-12">
            <div className="tab-content" id="pills-tabContent">
              {
                /*
                Temporarly disable Hot Deals and New Arrivals
                <NavigationTab
                  id="hot-deals"
                  offers={ hotDeals }
                  active={ category === 'hot-deals' }
                />

                <NavigationTab
                  id="new-arrivals"
                  offers={ newArrivals }
                  active={ category === 'new-arrivals' }
                />
                 */
              }

              <NavigationTab
                id="popular"
                offers={ popular }
                active={ category === 'popular' }
              />
            </div>
          </div>
        </div>
      </Loadable>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  hotDeals: selectors.getHotDeals(state),
  newArrivals: selectors.getNewArrivals(state),
  popular: selectors.getPopular(state)
});

const mapDispatchToProps = {
  fetchOffers: operations.fetchOffers
};

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
