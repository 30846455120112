import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export const PageViewTracker = ({ history, onLocationChanged }) => {
  useEffect(() => {
    return history.listen(({ pathname }) => onLocationChanged(pathname));
  }, []);

  return <></>;
};

PageViewTracker.propTypes = {
  onLocationChanged: PropTypes.func.isRequired
};

export default withRouter(PageViewTracker);
