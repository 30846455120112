import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

const QueryMatch = ({ location, paths }) => {
  const params = queryString.parse(location.search);

  if (params) {
    const matching = paths.find(
      ({ query, value }) => params[query] === value
    );

    if (matching) {
      return (
        <Redirect
          to={{
            pathname: matching.redirect,
            state: { params }
          }}
        />
      );
    }
  }

  return (
    <p>Component was not found</p>
  );
}

QueryMatch.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      redirect: PropTypes.string.isRequired
    })
  ).isRequired
};

export default QueryMatch;
