import React from 'react';
import PropTypes from 'prop-types';

import { List } from '.';

const StatefulList = ({ offers, emptyMessage }) => {
  if (offers.length > 0) {
    return (
      <List offers={ offers } />
    );
  }

  return (
    <p className="empty">{ emptyMessage }</p>
  );
}

StatefulList.propTypes = {
  offers: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string.isRequired
}

export default StatefulList;
