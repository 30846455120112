import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SignedOutHeader = ({ onLogInClick }) =>
  <header>
    <div className="container">
        <div className="row">
            <div className="col-sm-4 align-self-center">
                <Link to="/" title="Refkody">
                    <img src="/images/logo.svg" alt="Refkody"/>
                </Link>
            </div>
            <div className="col-sm-8 align-self-center">
                <nav className="nav nav-masthead">
                    <button
                      className="nav-link log-in-button"
                      onClick={ onLogInClick }
                    >
                      Zaloguj / zarejestruj
                    </button>

                    <Link
                      to="/offer/new"
                      className="nav-link outline"
                    >
                      Dodaj ofertę
                    </Link>
                    <Link className="btn-primary ml-2" to="/contact">
                      Kontakt
                    </Link>
                </nav>
            </div>
        </div>
    </div>
  </header>

SignedOutHeader.propTypes = {
  onLogInClick: PropTypes.func.isRequired
};

export default SignedOutHeader;
