import React from 'react';
import { connect } from 'react-redux';

import { Loadable } from '../../containers/shared';
import { Spinner } from '../../components/shared';
import { operations } from '../../reducers/auth';

class VerifyEmail extends React.Component {

  componentDidMount() {
    const { state } = this.props.location;

    if (state && state.params && state.params.oobCode) {
      const code = state.params.oobCode;

      if (code) {
        this.props.verifyEmail(code);
      }
    }
  }

  render() {
    const { state } = this.props.location;

    if (state && state.params && state.params.oobCode) {
      return (
        <Loadable scope="auth">
          <Spinner />
        </Loadable>
      );
    }

    return (
      <main>
        <p className="error">
          Wystąpił błąd podczas weryfikacji adresu e-mail. Upewnij się, że link bangla :)
        </p>
      </main>
    );
  }
}

const mapDispatchToProps = {
  verifyEmail: operations.verifyEmail
};

export default connect(null, mapDispatchToProps)(VerifyEmail);
