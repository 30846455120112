import React from 'react';
import { connect } from 'react-redux';

import { Container } from '../../components/shared';
import { Register } from '../../components/auth';
import { operations } from '../../reducers/auth';
import { selectors as uiSelectors } from '../../reducers/ui';

const RegisterContainer = ({ loading, error, register, registerWithFacebook, registerWithGoogle }) =>
  <Container>
    <div className="row">
      <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
        <div className="register-form p-md-5 p-3 mt-5 text-center">
          <img src="/images/refkody.svg" className="mb-4" alt="Refkody"/>

          <p className="medium">
            Dołącz do naszej społeczności i korzystaj z bonusów. Dziel się kodami polecającymi i referencyjnymi.
          </p>

          <Register
            loading={ loading }
            error={ error }
            onRegister={ register }
            onRegisterWithFacebook={ registerWithFacebook }
            onRegisterWithGoogle={ registerWithGoogle }
          />
        </div>
      </div>
    </div>
  </Container>

const ui = uiSelectors.forScope('register');

const mapStateToProps = (state) => ({
  loading: ui.isLoading(state),
  error: ui.getError(state)
});

const mapDispatchToProps = {
  register: operations.register,
  registerWithFacebook: operations.registerWithFacebook,
  registerWithGoogle: operations.registerWithGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
