import * as types from './types';

export const startLoading = (scope) => ({
  type: types.START_LOADING,
  payload: scope
});

export const stopLoading = (scope) => ({
  type: types.STOP_LOADING,
  payload: scope
});

export const error = (scope, e) => ({
  type: types.LOADING_ERROR,
  payload: {
    scope,
    error: e
  }
});

export const forScope = (scope) => ({
  startLoading: () => startLoading(scope),
  stopLoading: () => stopLoading(scope),
  error: (e) => error(scope, e)
});
